export const DEV_DEMO_HOST = 'dev-demo.internal.tf-b2c.com';
export const DEV_DEMO_HOST_1_30 = 'dev-demo.internal-v1-30.tf-b2c.com';
export const QA_DEMO_HOST = 'qa-demo.internal.tf-b2c.com';
export const QA_DEMO_HOST_1_30 = 'qa-demo.internal-v1-30.tf-b2c.com';
export const DEMO_STAGE_HOST = 'demo.stage.tf-b2c.com';
export const DEMO_STAGE_HOST_1_30 = 'demo.stage-v1-30.tf-b2c.com';
export const DEMO_PROD_HOST = 'demo.prod.tf-b2c.com';
export const DEMO_PROD_HOST_1_30 = 'demo.prod-v1-30.tf-b2c.com';
export const DEV_DEMO_BASE_URL = 'https://dev-webcheckout.internal.tf-b2c.com';
export const DEV_DEMO_BASE_URL_1_30 =
  'https://dev-webcheckout.internal-v1-30.tf-b2c.com';
export const QA_DEMO_BASE_URL = 'https://qa-webcheckout.internal.tf-b2c.com';
export const QA_DEMO_BASE_URL_1_30 =
  'https://qa-webcheckout.internal-v1-30.tf-b2c.com';
export const DEMO_STAGE_BASE_URL = 'https://webcheckout.stage.tf-b2c.com';
export const DEMO_STAGE_BASE_URL_1_30 =
  'https://webcheckout.stage-v1-30.tf-b2c.com';
export const DEMO_PROD_BASE_URL = 'https://webcheckout.prod.tf-b2c.com';
export const DEMO_PROD_BASE_URL_1_30 =
  'https://webcheckout.prod-v1-30.tf-b2c.com';
export const LOCAL_BASE_URL_HOST = 'http://localhost:8090';
export const LOCAL_BASE_URL_SDK = 'http://localhost:8080';
export const DEV_BIN_URL = 'https://dev-callbacks-bin.internal.tf-b2c.com';
export const DEV_BIN_URL_1_30 =
  'https://dev-callbacks-bin.internal-v1-30.tf-b2c.com';
export const QA_BIN_URL = 'https://qa-callbacks-bin.internal.tf-b2c.com/';
export const QA_BIN_URL_1_30 =
  'https://qa-callbacks-bin.internal-v1-30.tf-b2c.com/';
export const STAGE_BIN_URL = 'https://callbacks-bin.stage.tf-b2c.com/';
export const STAGE_BIN_URL_1_30 =
  'https://callbacks-bin.stage-v1-30.tf-b2c.com/';
export const PROD_BIN_URL = 'https://callbacks-bin.prod.tf-b2c.com/';
export const PROD_BIN_URL_1_30 = 'https://callbacks-bin.prod-v1-30.tf-b2c.com/';

export const Environment = Object.freeze({
  DEV: 'DEV',
  DEV_1_30: 'DEV_1_30',
  QA: 'QA',
  QA_1_30: 'QA_1_30',
  STAGE: 'STAGE',
  STAGE_1_30: 'STAGE_1_30',
  PROD: 'PROD',
  PROD_1_30: 'PROD_1_30',
  LOCAL: 'LOCAL'
});
